@import '../../marketplace.css';
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);
  position: static;
  top: 0;
  left: 0;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: transparent;

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

.defaultContainer {
  margin: 0;
  border: solid 1px #E6E6E6;
  overflow-y: auto;
  background: #FFFFFF;
  height: calc(100vh - 280px);
  
  @media (--viewportMobile) {
    margin:  -20px;
    border: none;
    background-color: #FCFCFC;
    height: calc(100vh - 300px);
  }
}

.simpleContainer {
  margin: -20px;
  overflow-y: auto;
  background: #FCFCFC;
  height: calc(100vh - 280px);
  
  @media (--viewportMobile) {
    margin: 0;
    border: none;
    height: 100vh;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  
  @media (--viewportMobile) {
    border-bottom: none;
  }
}

.header>p {
  /* border: solid 2px blue; */
  font-size: 24px;
  line-height: 56px;
  font-weight: 700 !important;
  color: #026786;
  padding: 10px 20px;
  margin :0;

  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 34px;
    padding: 10px 20px;
  }
}

.subHeader {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300 !important;
  padding: 10px 20px;
  color: #4A4A4A;
  margin: 0;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 23px;
    padding: 10px 20px;
  }
}

.subHeaderWithBorder {
  font-weight: 500 !important;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #E6E6E6;
    
    @media (--viewportMobile) {
      display: none;
    }
  }
}

.header .helpBtn {
  clear: both;
  display: block;
}

.header .needHelpBtn {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 4px;
  color: #026786;
  opacity: 1;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid #026786;
  @media (--viewportMobile) {
    width: 106px;
    height: 32px;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 5px;
  }
}

.header .needHelpBtn img {
  padding-right: 5px;
  width: 25px;
  height: 30px;
  @media (--viewportMobile) {
    width: 17px;
    height: 20px;
  }
}


.invoiceListContainer {
  min-height: 166px;
  background-color: #FFFFFF;
  /* border: solid 1px #E6E6E6; */
  border-top: 1px solid #E6E6E6;
  padding: 20px;
  margin: 0;

  @media (--viewportMobile) {
    margin: 0;
  }
}

.invoiceAmount {
  margin: 10px 0 12px 0px;
  color: #026786;
  font-size: 24px;
  font-weight: 700 !important;
  
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 22px;
  }
}

.invoiceNumber {
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 22px;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 17px;
  }
}
.invoiceDescription {
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 22px;
  
  @media (--viewportMobile) {
    line-height: 19px;
    font-size: 14px;
  }
}

.invoiceContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.payButton {
  width: 300px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFCD05;
  color: #026786;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.16px;
  margin-right: 0;
  @media (--viewportMobile) {
    width: 160px;
    height: 48px;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.16px;
  }
}

.payButton:hover {
  background: #FFCD05;
}

.bottomBar {
  /* position: fixed; */
  width: 100%;
  height: 100px;
  box-shadow: 0px -3px 6px #00000014;
  background-color: #FFFFFF;
  padding: 0 20px; 
  margin :0;
  z-index: 1000;

  @media (--viewportMobile) {
    width: auto;
    margin :-20px;
  }
}

.bottomBarContent {
  height: 100%;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  @media (--viewportMobile) {
    padding:0; 
  }
}

.totalAmount {
  font-size: 28px;
  line-height: 33px;
  color: #00A3AD;
  font-weight: 900;

  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 27px;
  }
}

.bottomBar>p {
  font-size: 28px;
  line-height: 33px;
  color: #00A3AD;
  font-weight: 900 !important;
  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 27px;
  }
}

.bottomBar>button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 60px;
  background-color: #FFCD05;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 4px;
  color: #026786;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700 !important;
  margin: 0;
  padding: 0;
  
  @media (--viewportMobile) {
    margin-right: 0;
    margin-left: 10px;
    height: 48px;
    font-size: 18px;
    line-height: 18px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.unpaidInvoicesContainer {
  position: relative;
  min-height: 200px;
  margin-bottom: 60px;
}

/* Custom scrollbar styling */
.defaultContainer::-webkit-scrollbar {
  width: 6px;
}

.defaultContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.defaultContainer::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.defaultContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.simpleContainer::-webkit-scrollbar {
  width: 6px;
}

.simpleContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.simpleContainer::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.simpleContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modalCustomContainer {
  padding: 12px !important;
  margin: 0 !important;
}

.modalScrollLayer {
  padding: 0 !important;
  margin: 0 !important;
}

.modalContent {
  padding: 0 !important;
  margin: 0 !important;
}

