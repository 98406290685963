.modificationChargesRoot {
  margin-top: 20px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 15px;
  border-radius: 4px;

  @media (--viewportMobile) {
    border-top: 0;
    border-bottom: 1px solid #e6e6e6;
    margin-top: 0;
    border-radius: 0;
  }

  .head {
    display: block;
    color: #7c7c7c;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
  }

  .headText {
    display: block;
    margin-bottom: 10px;
    color: #026786;
    font-size: 18px;
    font-weight: 700;
  }

  .headTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .lineItemsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .lineItemName {
    display: block;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 18px;
  }

  .lineItemValue {
    display: block;
    color: #1a1a1a;
    font-weight: 900;
    font-size: 18px;
  }

  .plus {
    color: #00a3ad;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}


.explanationBreakdownContainer {
  border: 1px dashed #ebebeb;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;

  .lineItem {
    margin-top: 5px !important;
  }
}

.checkoutBreakdownContainer {
  position: relative;
  margin-bottom: 20px;

  .lineItem {
    margin-top: 5px !important;
  }
}

.modalTitle {
  color: #0d6786;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}


.explanationContainerTitle {
  background-color: #ffffff;
  color: #026786;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 12px;
  transform: translateY(-50%);
}

.paidBanner {
  background-color: #00a3ad;
  color: #ffffff;
  padding: 4px;
  border-radius: 2px;
  margin-left: 10px;
}

.updateBookingSubTitle {
  display: flex;
  align-items: center;
  font-weight: 300 !important;
  margin: 0;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 24px;
}

.updateBookingSubTitle {
  align-items: center;

  @media (--viewportMobile) {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.listingPriceChange {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;

  svg {
    min-width: 16px;
    min-height: 16px;
    margin: 0;
  }
}
