@import '../../marketplace.css';

.modificationDatesRoot {
  margin-top: 20px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 15px;
  border-radius: 4px;

  @media (--viewportMobile) {
    border-radius: 0;
  }
}
.modificationDatesContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (--viewportMobile) {
    border-radius: 0;
    display: block;
  }
}

.updatedDatesWrapper {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 15px;
  border-radius: 4px;
}


.head {
  display: block;
  color: #7c7c7c;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
}

.headText {
  display: block;
  margin-bottom: 10px;
  color: #026786;
  font-size: 18px;
  font-weight: 700;
}

.datesContainerWrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  .item {
  }

  .original {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 700;
  }

  .updated {
    color: #026786;
    font-size: 18px;
    font-weight: 700;
  }
}

.datesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item {
  }

  .original {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 700;
  }

  .updated {
    color: #026786;
    font-size: 18px;
    font-weight: 700;
  }
}

.editButton {
  cursor: pointer;
  background-color: var(--marketplaceColor);
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.editIcon {
  padding: 3px;
}

.tripPeriodText {
  font-size: 16px;
  font-weight: 300;

  @media (--viewportMobile) {
    font-size: 15px;
  }
}

.clickButton {
  color: #026786;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 2px;
}
